<template>
    <div class="table">
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-lx-cascades"></i> 问卷管理</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="handle-box">
                <el-input v-model="select_word" placeholder="筛选关键词" class="handle-input mr10"></el-input>
                <el-button type="primary" icon="search" @click="search">搜索</el-button>
                <el-button type="primary" style="float: right" @click="handleEdit(undefined, undefined, 1)">添加</el-button>
            </div>
            <el-table :data="data" border class="table" ref="multipleTable" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column prop="id" label="ID" width="70" align="center"></el-table-column>
                <el-table-column prop="createname"  align="center" label="创建者" width="155">
                </el-table-column>
                <el-table-column prop="wjname"  align="center" label="问卷标题">
                </el-table-column>
                <el-table-column prop="countdown"  align="center" label="答题倒计时（单位：分钟）" width="200">
                </el-table-column>
                <el-table-column prop="qrcode"  align="center" label="页面二维码" width="200">
                    <template   slot-scope="scope">
                        <el-popover
                                placement="left"
                                title=""
                                width="500"
                                trigger="hover">
                            <img :src="scope.row.imgUrl" style="max-width: 100%" />
                            <img slot="reference" :src="scope.row.imgUrl" :alt="scope.row.imgUrl" style="max-width: 130px; height: auto; max-height: 100px">
                        </el-popover>
                        <!--<img :src="scope.row.b_image"  min-width="70" height="70" />-->
                    </template>
                </el-table-column>
                <el-table-column prop="datetime"  align="center" label="创建时间" sortable width="155">
                </el-table-column>
                <el-table-column label="操作" width="150" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit"><a :href="scope.row.imgUrl" download="qrcode.png" target="_blank">下载二维码</a></el-button>
                        <!--<el-button type="text" icon="el-icon-document">导出答卷数据</el-button>-->
                        <el-button type="text" icon="el-icon-edit" @click="handleEdit(scope.$index, scope.row, 2)">编辑</el-button>
                        <el-button type="text" icon="el-icon-delete" class="red" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                        <!--<export-excel :list="excelData" :filename="filename" :tHeader="tHeader" :tValue="tValue"></export-excel>-->
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination background @current-change="handleCurrentChange" layout="prev, pager, next" :total="sumPage">
                </el-pagination>
            </div>
        </div>

        <!-- 编辑弹出框 -->
        <el-dialog title="编辑" v-loading="loading" :visible.sync="editVisible" width="70%">
            <el-form ref="form" :rules="rules" :model="form" label-width="120px">
                <el-form-item label="创建者姓名" prop="createname">
                    <el-input v-model="form.createname" style="width:600px;" placeholder="请输入创建者姓名"></el-input>
                </el-form-item>
                <el-form-item label="问卷标题" prop="wjname">
                    <el-input v-model="form.wjname" type="textarea" style="width:600px;" :rows="3" placeholder="请输入问卷标题"></el-input>
                </el-form-item>
                <el-form-item label="答题倒计时" prop="countdown">
                    <!--<el-input v-model="form.countdown" placeholder="请输入答题倒计时"></el-input>-->
                    <el-slider
                            v-model="form.countdown"
                            :step="5"
                            show-stops>
                    </el-slider>
                    <span style="color:red">单位：分钟，默认10分钟</span>
                </el-form-item>
                <el-form-item label="题目">
                    <el-form-item class="itemDetails" v-for="(item,index) in form.titleList" :key="item.id">
                        <el-form-item label="问题">
                            <!--<el-input v-model="form.sort" style="width:100px"></el-input>天-->
                            <el-input placeholder="请输入问题" type="textarea"  style="width:600px; margin-right: 20px" :rows="3" v-model="item.title"></el-input>
                        </el-form-item>
                        <el-form-item label="分数">
                            <el-input style="width:100px;" v-model="item.fraction"></el-input>
                        </el-form-item>
                        <el-form-item label="问题类型">
                            <el-select v-model="item.type" style="margin-right: 20px">
                                <el-option
                                        v-for="(item,index) in mediaList"
                                        :key="index"
                                        :label="item.title"
                                        :value="item.id">{{item.title}}
                                </el-option>
                            </el-select>

                            <el-button type="success" v-if="item.type==0 || item.type==3" icon="el-icon-edit" @click="addOptions(index)">添加选项</el-button>
                            <el-button type="warning" v-if="item.type==0 || item.type==3" icon="el-icon-delete" @click="reduceOptions(index)">减少选项</el-button>

                        </el-form-item>
                        <!--单选题选项-->
                        <el-radio-group v-model="item.radio" v-if="item.type==0">
                            <el-form-item :label="letter[i]" v-for="(value,i) in item.optionsList" :key="i">
                                <el-input v-model="value.content" style="width:300px; margin-right:15px;" placeholder="请输入选项"></el-input>
                                <el-radio :label="i">正确答案</el-radio>
                            </el-form-item>
                        </el-radio-group>
                        <!--多选题选项-->
                        <el-form-item label=" " v-if="item.type==3">
                            <el-checkbox-group v-model="item.checked">
                                <el-form-item v-for="(value,i) in item.optionsList" :key="i">
                                    <el-input v-model="value.content" style="width:300px; margin-right:15px;" placeholder="请输入选项"></el-input>
                                    <el-checkbox style="margin-right:20px" :label="i">正确答案</el-checkbox>
                                </el-form-item>
                            </el-checkbox-group>
                        </el-form-item>
                        <!--填空题答案-->
                        <el-form-item label="正确答案" v-if="item.type==1">
                            <!--<el-input v-model="form.sort" style="width:100px"></el-input>天-->
                            <el-input placeholder="请输入填空题答案"  style="width:300px; margin-right: 20px" v-model="item.nullAnswer"></el-input>
                        </el-form-item>
                        <!--问答题答案-->
                        <el-form-item label="正确答案" v-if="item.type==2">
                            <!--<el-input v-model="form.sort" style="width:100px"></el-input>天-->
                            <el-input placeholder="请输入问答题答案" type="textarea" :rows="5" style="width:600px; margin-right: 20px" v-model="item.QusAnswer"></el-input>
                        </el-form-item>

                        <el-form-item style="margin-left: 120px">
                            <el-button type="primary" icon="el-icon-edit" @click="addProcess(index)">保存</el-button>
                            <el-button type="danger" icon="el-icon-delete" @click="delProcess(index)">删除</el-button>
                        </el-form-item>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="success" @click="addMoreProcess()">添加更多题目</el-button>
                    </el-form-item>
                    <!--<quill-editor ref="myTextEditor" v-model="form.details" :options="editorOption"></quill-editor>-->
                    <!--<el-button class="editor-btn" type="primary" @click="submit">提交</el-button>-->
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="hideEditVisible">取 消</el-button>
                <el-button type="primary" @click="saveEdit('form')">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 删除提示框 -->
        <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
            <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="delVisible = false">取 消</el-button>
                <el-button type="primary" @click="deleteRow">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import  ExportExcel from '.././utils/index';
    export default {
        name: 'basetable',
        components: { ExportExcel},
        data() {
            return {
                url: './vuetable.json',
                tableData: [],
                letter :['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
                cur_page: 1,  //当前页
                number: 10,  //每页显示条数
                sumPage:10,  //总页数
                multipleSelection: [],
                select_cate: '',
                select_word: '',
                del_list: [],
                is_search: false,
                editVisible: false,
                delVisible: false,
                form: {
                    id:'',
                    createname:'',
                    wjname: '',
                    countdown: '',
                    qrcode: '',
                    imgUrl: '',
                    titleList: [],
                    datetime: '',
                },
                idx: -1,
                dialogVisible: false,
                AddOrSave:'',  //1表示添加，2表示更新
                loading:false,
                rules: {
                    createname: [
                        { required: true, message: '请输入创建者姓名', trigger: 'blur' }
                    ],
                    wjname: [
                        { required: true, message: '请输入问卷标题', trigger: 'blur' }
                    ],
                },
                mediaList: [  //上传类型列表
                    { id: '0', title: '单选题' },
                    { id: '3', title: '多选题' },
                    { id: '1', title: '填空题' },
                    { id: '2', title: '问答题' },
                ],
                excelData:[],  //表格数据
                tHeader:['ID', '微信昵称', '性别', '国家', '省份', '城市', '手机号码', '更新时间'],
                tValue:['id', 'nickname', 'sex', 'country', 'province', 'city','tel','datetime'],
                filename:'微商城所有用户数据',
            }
        },
        created() {
            //获取初始数据
            this.getData();
            //请求要导出的表格数据
            this.exportExcel();
        },
        computed: {
            data() {
                return this.tableData.filter((d) => {
                    let is_del = false;
                    for (let i = 0; i < this.del_list.length; i++) {
                        if (d.prize === this.del_list[i].prize) {
                            is_del = true;
                            break;
                        }
                    }
                    return d;
                    // if (!is_del) {
                    //     if (d.b_datetime.indexOf(this.select_cate) > -1 &&
                    //         (d.b_titile.indexOf(this.select_word) > -1 ||
                    //             d.b_datetime.indexOf(this.select_word) > -1)
                    //     ) {
                    //         return d;
                    //     }
                    // }
                })
            }
        },
        methods: {
            //请求表格数据
            exportExcel(){
                // console.log(111);
                this.$api.post('ShopUser/exportExcel', null, res => {
                    // console.log(res);
                    this.excelData=res.data;
                    // console.log(this.excelData);
                    // this.$message.success(res.msg);
                }, err => {
                    console.log(err);
                    // this.$message.error(err.msg);
                });
            },
            //设置上传图片接口地址
            uploadUrl(){
                var url=this.$api.uploadUrl + "/Images/upload";
                return url
            },
            //图片上传之前
            beforeAvatarUpload(file){
                // console.log(file);
                this.loading=true;
            },
            //正在上传中
            uploading(event, file, fileList){
                // console.log(event);
                // console.log(file);
                // console.log(fileList);
            },
            //图片上传失败
            uploadError(err){
                this.$message.error(err.msg);
            },
            //图片上传成功
            handleAvatarSuccess(res, file){
                this.loading=false;
                // console.log(res);
                this.form.imgid=res.data;
                this.form.imgUrl = URL.createObjectURL(file.raw);
                this.getData();
                this.$message.success(res.msg);
            },
            // 分页导航
            handleCurrentChange(val) {
                this.cur_page = val;
                this.getData();
            },
            // 获取 easy-mock 的模拟数据
            getData() {
                // 开发环境使用 easy-mock 数据，正式环境使用 json 文件
                // if (process.env.NODE_ENV === 'development') {
                //     this.url = '/ms/table/list';
                // };
                //不传参数要写null
                var params=this.$qs.stringify({
                    select_word: this.select_word,
                    pageIndex: this.cur_page,
                    number: this.number
                });
                // console.log(params);
                this.$api.post('InsideQus/getQusList', params, res => {
                    this.tableData = res.data.list;
                    this.sumPage = res.data.sumPage*10;
                    this.cur_page = res.data.currentPage;
                    console.log(this.tableData);
                }, err => {
                    this.tableData = [];
                    this.$message.error(err.msg);
                });
                // this.$axios.post(this.url, {
                //     page: this.cur_page
                // }).then((res) => {
                //     this.tableData = res.data.list;
                //     console.log(this.tableData);
                // })
            },
            search() {
                this.is_search = true;
                this.getData();
            },
            formatter(row, column) {
                return row.prize;
            },
            filterTag(value, row) {
                return row.tag === value;
            },
            handleEdit(index, row, status) {
                this.AddOrSave=status;
                //如果是添加则把form清空
                if(status==1){
                    this.form = {
                        id:null,
                        createname: null,
                        wjname: null,
                        countdown: 10,
                        qrcode: null,
                        imgUrl: null,
                        datetime: null,
                        titleList: [],
                    };
                }
                if(index!=undefined && row!=undefined){
                    this.idx = index;
                    const item = this.tableData[index];
                    this.form = {
                        id:item.id,
                        createname: item.createname,
                        wjname: item.wjname,
                        countdown: parseInt(item.countdown),
                        qrcode: item.qrcode,
                        imgUrl: item.imgUrl,
                        datetime: item.datetime,
                        titleList: item.titleList,
                    };
                }
                this.editVisible = true;
            },
            handleDelete(index, row) {
                this.idx = index;
                this.form = row;
                this.delVisible = true;
            },
            delAll() {
                const length = this.multipleSelection.length;
                let str = '';
                this.del_list = this.del_list.concat(this.multipleSelection);
                for (let i = 0; i < length; i++) {
                    str += this.multipleSelection[i].b_title + ' ';
                }
                console.log(this.del_list);
                // this.$message.error('删除了' + str);
                // this.multipleSelection = [];
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            // 保存编辑
            saveEdit(formName) {
                // this.$set(this.tableData, this.idx, this.form);
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.editVisible = false;
                        var params=null;
                        //1表示添加，2表示更新
                        if(this.AddOrSave==1){
                            let titleids=[];
                            this.form.titleList.forEach(function(item, index, arr) {
                                titleids.push(item.id);
                            });
                            params={
                                createname: this.form.createname,
                                wjname: this.form.wjname,
                                countdown: this.form.countdown,
                                titleids: titleids.join(',')
                            };
                        }else{
                            params={
                                id: this.form.id,
                                createname: this.form.createname,
                                wjname: this.form.wjname,
                                countdown: this.form.countdown,
                                titleids: null
                            };
                        }
                        this.$api.post('InsideQus/saveQus', this.$qs.stringify(params), res => {
                            console.log(res);
                            this.getData();
                            this.$message.success(res.msg);
                        }, err => {
                            this.$message.error(err.msg);
                        });
                    }else{
                        console.log("请填写所需数据");
                        return false;
                    }
                });
            },
            // 确定删除
            deleteRow(){
                // console.log(this.form);
                // return;
                var params=this.$qs.stringify({
                    id: this.form.id
                });
                this.$api.post('InsideQus/deleteQus', params, res => {
                    this.getData();
                    this.$message.success(res.msg+res.data+"条数据");
                }, err => {
                    this.$message.error(err.msg);
                });
                this.delVisible = false;
            },
            hideEditVisible(){
                this.editVisible=false;
            },
            //添加更多题目
            addMoreProcess(){
                let temp={
                    qid: this.form.id,
                    qname: this.form.wjname,
                    title: null,
                    type: '0',
                    optionsList:[],
                    nullAnswer: null,
                    radio: 0,
                    QusAnswer: null,
                    fraction: null,
                    checked: [],
                };
                this.form.titleList.push(temp);
                console.log(this.form.titleList);
            },
            //添加选项
            addOptions(index){
                let temp={
                    tid: this.form.titleList[index].id,
                    content: null,
                    isright: '0',
                };
                this.form.titleList[index].optionsList.push(temp);
                console.log(this.form.titleList);
            },
            //减少选项
            reduceOptions(index){
                this.form.titleList[index].optionsList.pop();
            },
            //保存题目
            addProcess(index){
                let item=this.form.titleList[index];
                let params=null;
                if(parseInt(item.type)==0){
                    //单选题数据处理方式
                    for (let i=0; i<item.optionsList.length; i++){
                        item.optionsList[i].isright=0;
                    }
                    item.optionsList[item.radio].isright=1;  //正确答案
                    params={
                        id: item.id ? item.id: null,
                        qid: this.form.id ? this.form.id : null,
                        qname: this.form.qname ? this.form.qname : null,
                        title: item.title,
                        type: 0,
                        optionsList: item.optionsList,
                        fraction: item.fraction
                    };
                }else if(parseInt(item.type)==3){
                    //多选题数据处理方式
                    console.log(item.checked);
                    for (let i=0; i<item.optionsList.length; i++){
                        item.optionsList[i].isright=0;
                    }
                    for(let j=0;j<item.checked.length; j++){
                        item.optionsList[item.checked[j]].isright=1;
                    }
                    params={
                        id: item.id ? item.id: null,
                        qid: this.form.id ? this.form.id : null,
                        qname: this.form.qname ? this.form.qname : null,
                        title: item.title,
                        type: 3,
                        optionsList: item.optionsList,
                        fraction: item.fraction
                    };
                }else if(parseInt(item.type)==1){
                    //填空题数据处理方式
                    params={
                        id: item.id ? item.id: null,
                        qid: this.form.id ? this.form.id : null,
                        qname: this.form.qname ? this.form.qname : null,
                        title: item.title,
                        type: 1,
                        answer: item.nullAnswer,
                        fraction: item.fraction
                    };
                }else if(parseInt(item.type)==2){
                    //问答题数据处理方式
                    params={
                        id: item.id ? item.id: null,
                        qid: this.form.id ? this.form.id : null,
                        qname: this.form.qname ? this.form.qname : null,
                        title: item.title,
                        type: 2,
                        answer: item.QusAnswer,
                        fraction: item.fraction
                    };
                }
                console.log("我是参数：",params);
                // return;
                this.$api.post('InsideQus/addOrSaveTitle', this.$qs.stringify(params), res => {
                    this.$message.success(res.msg);
                    console.log(res);
                    item.id=res.data;

                }, err => {
                    this.$message.error(err.msg);
                });
            },
            //删除题目
            delProcess(index){
                let temp=this.form.titleList[index];
                if(!temp.id){
                    var tempArr=this.form.titleList;
                    tempArr.forEach(function(item, index, arr) {
                        if(item.id == temp.id) {
                            arr.splice(index, 1);
                        }
                    });
                }else{
                    let params=this.$qs.stringify({
                        id: temp.id,
                    });
                    this.$api.post('InsideQus/delProcess', params, res => {
                        this.$message.success(res.msg);
                        console.log(res);
                        var tempArr=this.form.titleList;
                        tempArr.forEach(function(item, index, arr) {
                            if(item.id == temp.id) {
                                arr.splice(index, 1);
                            }
                        });
                    }, err => {
                        this.$message.error(err.msg);
                    });
                }

            },



        }
    }

</script>

<style scoped>
    .handle-box {
        margin-bottom: 20px;
    }

    .handle-select {
        width: 120px;
    }

    .handle-input {
        width: 300px;
        display: inline-block;
    }
    .del-dialog-cnt{
        font-size: 16px;
        text-align: center
    }
    .table{
        width: 100%;
        font-size: 14px;
    }
    .red{
        color: #ff0000;
    }
    .mr10{
        margin-right: 10px;
    }
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 100%;
        /*height: 100%;*/
        display: block;
    }
    .itemDetails{
        padding: 18px;
        border: 1px dashed #409EFF
    }
</style>
